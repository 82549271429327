body {
  overflow-x: hidden !important;
}
.header {
  background-color: #4cc5c9;
  width: 90%;
  height: 400px;
  justify-self: center;
  margin-top: 3vw;
  margin-left: 5vw;
  margin-bottom: 2vw;
  border-radius: 50px;
}

.product__card {
  margin: 40px;
  width: 80%;
}

.card-image {
  object-fit: cover;
}

@media only screen and (max-width: 1180px) {
  .header {
    height: 600px !important;
  }
  .header__img {
    height: 500px !important;
    width: 100% !important;
    margin-top: 40px !important;
  }
}

@media only screen and (max-width: 748px) {
  .header {
    height: 800px !important;
  }
  .header__img {
    height: 400px !important;
    width: 100% !important;
    margin-top: 140px !important;
  }
}

@media only screen and (max-width: 1055px) {
  .header__title {
    font-size: 35px !important;
  }
  .header__title__content {
    font-size: 15px !important;
  }
  .header__img {
    height: 500px !important;
    width: 100% !important;
  }
  /* .product__card {
    margin: 0px !important;
    width: 100%;
  } */
}

@media only screen and (max-width: 780px) {
  .product__card {
    margin: 0px !important;
    margin-top: 5px !important;
    width: 100%;
  }
}
@media only screen and (max-width: 580px) {
  .header {
    height: 650px !important;
  }
  .header__title {
    font-size: 20px !important;
  }
  .header__title__content {
    font-size: 12px !important;
  }
  .header__img {
    height: 300px !important;
    width: 100% !important;
    margin-top: 100px !important;
  }
}

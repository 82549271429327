body {
  overflow-x: hidden;
}
.contact_mask {
  position: absolute;
  top: 65px;
  left: 0;
  width: 100%;
  height: 785px;
  background-color: black;
  opacity: 0.5;
  justify-content: center;
  justify-items: center;
}
.contact__mask__content {
  margin-top: 140px;
}

.contact_title_mask {
  color: white;
  font-size: 4.4vw;
  font-weight: 900;
  margin-left: 40px;
}
.contact_prag_mask {
  color: white;
  margin-left: 40px;
  font-size: 1.5vw;
}

@media only screen and (max-width: 770px) {
  .contact__info {
    border-top-left-radius: 50px !important;
    border-top-right-radius: 50px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    left: 8vw !important;
    margin-bottom: 10px;
    height: 480px !important;
  }
  .contact__message {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    left: 8vw !important;
    right: 0px !important;
    height: 480px !important;
  }
}

body {
  overflow-x: hidden;
}
div {
  padding: 0px !important;
}
.mask {
  position: absolute;
  top: 65px;
  left: 0;
  width: 100%;
  height: 835px;
  background-color: black;
  opacity: 0.5;
  justify-content: center;
  justify-items: center;
}
.title_mask {
  color: white;
  font-size: 4vw;
  font-weight: 900;
  margin: 0px !important;
  text-align: center;
}
.prag_mask {
  color: white;
  margin-left: 15%;
  font-size: 1.5vw;
}
.mask__content {
  margin-top: 7vw !important;
}

.card-image {
  object-fit: cover;
  transition: transform 0.6s ease;
}

.card-container:hover .card-image {
  transform: rotate(360deg);
}
.aboutus__title {
  font-size: 40px;
  font-weight: 900;
  margin-top: 20px;
  margin-bottom: 20px;
}
.aboutus__content {
  font-size: 25px;
}
@media only screen and (max-width: 2200px) {
  .mask__content {
    margin-top: 14vw !important;
  }
}

@media only screen and (max-width: 1300px) {
  .mask__content {
    margin-top: 23vw !important;
  }
}

@media only screen and (max-width: 1000px) {
  .mask__content {
    margin-top: 35vw !important;
  }
}
@media only screen and (max-width: 500px) {
  .mask__content {
    margin-top: 65vw !important;
  }
}
